<template>
  <v-app v-if="!isPrinting">
    <nfc-snackbar
      id="snackbarForBooster"
      v-model="isOpenSnackbar"
      :timeout="2000"
      :color="getColor"
      :message="getMessage"
    />
    <v-main>
      <nfc-bar color="primary">
        <v-toolbar-title class="white--text title">
          <v-icon class="mr-2" color="white" size="x-large">mdi-qrcode</v-icon>

          <b class="mt-6 title">BOOSTER LABELS</b>
        </v-toolbar-title>
      </nfc-bar>
      <v-container>
        <router-view />

      </v-container>
    </v-main>
       <nfc-pwa />
  </v-app>
  <div id="x" v-else>
         <router-view />
       
  </div>
 
</template>
<script>
import NfcBar from "./components/atoms/bar/NfcBar.vue";
import { useMessageStore } from "@/store/message";
import NfcSnackbar from "@/components/atoms/snackbar/NfcSnackbar.vue";
import { mapState } from "pinia";
import NfcPwa from "@/components/molecules/popup/NfcPwa.vue";


export default {
  components: {
    NfcPwa,
    NfcBar,
    NfcSnackbar,
  },


 

    

    computed: {
    isPrinting() {
      return this.$route.query.print === "true";
    },
    ...mapState(useMessageStore, ["getMessage", "getColor", "getIcon"]),
    isOpenSnackbar: {
      get() {
        return this.messageStore.isOpen;
      },
      set(value) {
        this.messageStore.setOpen(value);
      },
    },
  },
  setup() {
    return {
      messageStore: useMessageStore(),
    };
  },
};
</script>
<style scoped>
.title {
  font-family: revert;
/*  text-underline-offset: 6px;
  text-decoration: underline;
  text-decoration-thickness: 3px; */
}
#x {
  display: flex;
  flex-wrap: wrap;
 
  width: 100%;
}
</style>