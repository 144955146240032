export default {
    HOME: {
        name: 'home',
        path: '/',
    },
    QR_LIST: {
        name: 'qrList',
        path: '/qr-list',
    },
    
    QR_FORM: {
        name: "qrEdit",
        path: '/qr-edit/:id',

    },
    
    LOGIN: {
        name: 'login',
        path: '/login',
    },
    CHATBOT: {
        name: 'chatbot',
        path: '/chatbot/:id',
    },
    VIRTUAL_CARD: {
        name: 'virtualCard',
        path: '/virtual-card/:id',
    },
    QR_CODE_GENERATOR: {
        name: 'qrCodeGenerator',
        path: '/qr',
    },
    CARD_USER: {
        name: 'cardUser',
        path: '/cards/:id',
    },
    REGISTER: {
        name: 'register',
        path: '/register',
    },
    PROFILE_ID: {
        name: 'profiles',
        path: '/profiles/:id',
    },
    EDIT_PROFILE_ID: {
        name: 'editProfile',
        path: '/profiles/:id/edit',
    },
    PROFILE_CONTACTS: {
        name: 'profileContacts',
        path: '/profiles/:id/contacts',
    },
    COMPANY_ID: {
        name: 'companies',
        path: '/companies/:id',
    },
    EDIT_COMPANY_ID: {
        name: 'editCompany',
        path: '/companies/:id/edit',
    },
    COMPANY_STATISTIC_ID: {
        name: 'companyStatistic',
        path: '/companies/:id/statistic',
    },
    SUPPORT_CARD: {
        name: 'supportCard',
        path: '/support/cards/:id',
    },

    ASSOCIATE_TAGS_TO_ADDRESS: {
        name: 'associateTagsToAddress',
        path: '/associate-tags-to-address/:id',
    },
    ASSOCIATE_BUSINESS_CARD_TO_USER: {
        name: 'associateBusinessCardToUser',
        path: '/associate-business-card-to-user/:id',
    },
    GET_TOKEN: {
        name: 'getToken',
        path: '/get-token',
    },
    ADMIN: {
        name: 'admin',
        path: '/admin',
    },
    RENEW_PASSWORD: {
        name: 'renewPassword',
        path: '/reset-password/:token',
    },


}