<template>

<v-bottom-sheet v-model="getInstallable">


  <v-card>
   
      <v-card-title>Install this app on your device?</v-card-title>
      <v-card-text class="text-center">
         <center>
               <nfc-btn @click="dismiss" color="red" variant="plain"
                prepend-icon="mdi-close"
               >No Thanks</nfc-btn>
             <nfc-btn @click="installApp" color="primary" class="ml-2" 
                prepend-icon="mdi-download"
             >Install</nfc-btn>
     
         </center>
      </v-card-text>
   
    </v-card>
</v-bottom-sheet>

</template>

<script>
import { useInstallStore } from '@/store/installStore';
import { triggerInstallPrompt } from '@/main';
import NfcBtn from '@/components/atoms/btn/NfcBtn.vue';
import { mapState } from "pinia";

export default {
  components: { NfcBtn },
  computed: {
    ...mapState(useInstallStore, ['getInstallable']),
  },
  setup() {
    const installStore = useInstallStore();

    const installApp = () => {
      triggerInstallPrompt();
      installStore.setInstallable(false);
    };

    const dismiss = () => {
      installStore.setInstallable(false);
    };

    return {
      installApp,
      dismiss,
    };
  },
};
</script>

<style scoped>
.install-popup {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
</style>