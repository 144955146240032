import COLOR from "@/constants/color"
import { defineStore } from "pinia"
import { $t } from "@/adapters/i18n/index";
export const useMessageStore = defineStore('snackbar', {
    state: () => ({
        message: "", open: false, color: COLOR.INFO,

        icon: undefined
    }),
    getters: {
        getColor: (state) => state.color,
        getMessage: (state) => state.message,
        getIcon: (state) => state.icon,
        isOpen: (state) => state.open,
    },
    actions: {
        setErrorMessage({ message, icon }) {
            this.color = COLOR.ERROR
            this.message = message ?? $t("message.error")
            this.icon = icon ?? "mdi-warning"
            this.open = true
        },
        setMessage({ message, color, icon }) {
    
            this.color = color ?? COLOR.INFO
            this.message = message ?? ""
            this.icon = icon ?? undefined
            this.open = true
        },
        setOpen(value) {
            this.open = value ?? false
        },
    },
})