import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import VueQRCodeComponent from 'vue-qrcode-component'
import { i18n } from "./adapters/i18n";
import { createPinia } from 'pinia'
import { VAutocomplete } from 'vuetify/components';
import { useInstallStore } from '@/store/installStore';

// IMPORTANT: required when treeshaking Vuetify components.

import './assets/css/main.css';

import './registerServiceWorker'

let deferredPrompt = null;


window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  deferredPrompt = e;
  const installStore = useInstallStore();
  installStore.setInstallable(true); // Set the Pinia store state to show the popup
});


// Function to trigger the PWA install prompt
export function triggerInstallPrompt() {
  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      deferredPrompt = null;
    });
  }
}




const pinia = createPinia()





loadFonts();



createApp(App)
  .use(router)

  .use(pinia)
  .use(i18n)
  .component('qr-code', VueQRCodeComponent)
  .use(vuetify)
  .component('VAutocomplete', VAutocomplete)
  .mount('#app');

