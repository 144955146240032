import { createRouter, createWebHistory } from 'vue-router';

import ROUTES from '@/constants/routes';
import token from '@/adapters/token/token';


const routes = [
  {
    ...ROUTES.QR_LIST,
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "qr" */ '../views/QrListView.vue'),
  },
  {
    ...ROUTES.QR_FORM,
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "qr" */ '../views/QrFormView.vue'),
  },

  {
    ...ROUTES.QR_CODE_GENERATOR,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "qr" */ '../views/QrCodeGeneratorView.vue'),

  },
  {
    ...ROUTES.LOGIN,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
  },{
    ...ROUTES.REGISTER,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView.vue'),

  },
  {
    ...ROUTES.HOME,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
  },

  {
    ...ROUTES.RENEW_PASSWORD,
    meta: { requiresAuth: false, editor: true },
    component: () => import(/* webpackChunkName: "about" */  '../views/RenewPassword.vue'),
  },
 

];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth && token.hasToken() === false) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: ROUTES.LOGIN.path,
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    }
  }
})

export default router;
