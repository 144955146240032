import { defineStore } from 'pinia';

export const useInstallStore = defineStore('installStore', {
  state: () => ({
    isInstallable: false,
  }),
    getters: {
        getInstallable() {
        return this.isInstallable;
        },
    },
  actions: {
    setInstallable(payload) {
      this.isInstallable = payload;
   
    },
  },
});